const sendContactMessage = async (name,email,message) => {

    const jsonResponse = await fetch(`https://us-central1-portfolio-backend-7b69d.cloudfunctions.net/sendMail?dest=${email}&name=${name}&message=${message}`);

    if(!jsonResponse.ok){
        throw new Error('Something went wrong!')
    }

    const response = await jsonResponse.json();
    
    return response;
}

export default sendContactMessage;