import React, { useState, useEffect } from 'react';
import styles from './NavBar.module.css';
import logo from './../../assets/logo.png';

const NavBar = () => {
    
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 700;

  return (
    <nav className={styles.NavBar}>
      {!isMobile && (
        <div className={styles.Logo}>
          <img src={logo} alt="Logo" />
        </div>
      )}
      <ul
        className={`${styles.Options} ${
          isMobile ? styles.CenteredOptions : ''
        }`}
      >
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;