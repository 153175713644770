import React from 'react'
import styles from './CarouselItem.module.css';
const CarouselItem = ({img, alt}) => {
  return (
    <div className={styles.CarouselItem}>
        <img src={img} alt={alt}/>
    </div>
  )
}

export default CarouselItem