import React from 'react'
import styles from './Portfolio.module.css'
import Card from '../../components/Card/Card'
import netflix from './../../assets/Pages/netflix.png'
import gifapp from './../../assets/Pages/gifapp.png'
import beats from './../../assets/Pages/beats.png'

const Portfolio = () => {
  return (

    <div className={styles.PortfolioMain} id="portfolio">

        <div className={styles.Title}>

          <h1> Portfolio </h1>

        </div>

        <div className={styles.Cards}>

            <div className={styles.Card}>

                <Card
                title="Netflix Clone"
                image={netflix}
                link="https://and-netflix.netlify.app"
                technologies="MERN, Redux, Firebase, Docker, Google Cloud, Git."
                github="https://github.com/anuevoperez/Netflix-clone"
                />

            </div>

            <div className={styles.Card}>

                <Card
                title="Beat Web Model"
                image={beats}
                link="https://and-beatsmodelpage.netlify.app/"
                technologies="React, Firebase, Git."
                github="https://github.com/anuevoperez/Beats-model-web-page"
                />
                
            </div>

            <div className={styles.Card}>

                <Card
                title="Gif's App"
                image={gifapp}
                link="https://and-gifapp.netlify.app/"
                technologies="React, API Giphy, Git."
                github="https://github.com/anuevoperez/Gif-App"
                />
                
            </div>
                
        </div>

    </div>
    
  )
}

export default Portfolio