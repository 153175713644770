import css from '../assets/Skills/css.png'
import docker from './../assets/Skills/docker.png'
import express from './../assets/Skills/express.png'
import github from './../assets/Skills/github.png'
import html from './../assets/Skills/html.png'
import jest from './../assets/Skills/jest.png'
import js from './../assets/Skills/js.png'
import mongodb from './../assets/Skills/mongodb.png'
import mysql from './../assets/Skills/mysql.png'
import node from './../assets/Skills/node.png'
import react from './../assets/Skills/react.png'
import rtt from './../assets/Skills/rtt.png'


const Skills = 

    [
        {
            img: html,
            alt: "Logo HTML"
        },

        {
            img: css,
            alt: "Logo de CSS"
        },

        {
            img: js,
            alt: "Logo de JavaScript"
        },       
        
        {
            img: express,
            alt: "Logo de Express"

        },

        {
            img: react,
            alt: "Logo de ReactJs"
        },

        {
            img: node,
            alt: "Logo de NodeJs"
        },
    

        {
            img: jest,
            alt: "Logo de Jest"
        },

        {
            img: rtt,
            alt: "Logo de React Testing Library"
        },

        {
            img: mongodb,
            alt: "Logo de MongoDB"
        },


        {
            img: mysql,
            alt: "Logo de MySQL"
        },


        {
            img: docker,
            alt: "Logo de Docker"
        },

        {
            img: github,
            alt: "Logo de GitHub"
        },

    ];

export default Skills;