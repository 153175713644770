import React from "react";
import styles from "./Card.module.css";

const Card = ({ title, image, link, technologies, github }) => {

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <div className={styles.Card}>
        <img src={image} alt={title} />

        <div className={styles.Overlay}>
          <h3> {title} </h3>
          <p> {technologies} </p>
          <a href={github}>
            {" "}
            <ion-icon name="logo-github"></ion-icon> Github{" "}
          </a>
        </div>
      </div>
    </a>
  );
};

export default Card;
