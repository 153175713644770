import React from "react";
import styles from "./About.module.css";
import Carousel from "../../components/Carousel/Carousel";
import Skills from "../../services/Skills";
import abstract from "./../../assets/abstract.webp";

const About = () => {
  return (
    <div className={styles.AboutMain} id="about">
      <div className={styles.About}>
        <div className={styles.Title}>
          <h1> About </h1>
        </div>

        <div className={styles.AboutBody}>
          <div className={styles.AboutText}>
            <h2> Who am I? </h2>
            <h3> I'm Adrián Nuevo, full stack developer. </h3>
            <span>
              Born in Madrid in 1994. A full stack programmer experienced in web
              development. I like technology and I enjoy learning and exploring
              new tools and trends in the programming area. I'm always looking
              for exciting and challenging opportunities to apply my skills and
              expierence as fullstack developer and continue growing
              professionally in the programming industry.
          
            </span>
          </div>

          <div className={styles.AboutSkills}>

            <h2> Skills </h2>

            <Carousel items={Skills} />

            <div className={styles.Abstract}>
              <img src={abstract} alt="Fondo abstracto"></img>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
