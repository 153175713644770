import React from 'react'
import styles from './Home.module.css'
import avatar from './../../assets/avatar.png'
import cv from './../../assets/CV/cv.pdf'

const Home = () => {
  return (

    <div className={styles.HomeMain} id="home"> 

      <div className={styles.HomeContent}>
        
        <div className={styles.Photo}>

            <img src={avatar} alt="Profile ph"/>

        </div>

        <div className={styles.Content}>
        
          <div className={styles.Info}>

            <h3> Hi! </h3>
            <h1> I'm Adrián Nuevo </h1>
            <h3> Full-stack developer </h3>

          </div>
          
          <div className={styles.Links}>

            <a href="mailto:anuevoweb@gmail.com?Subject=%C2%A1Hola%21%20%C2%BFC%C3%B3mo%20va%20todo%3F"> 
              <ion-icon name="mail-sharp" 
                class={styles.icon}>
              </ion-icon> 
                Contact me 
            </a>

            <a href="https://linkedin.com/in/nuevoperezadrian" target='_blank' rel='noreferrer'> 
              <ion-icon name="logo-linkedin"
                class={styles.icon}>
              </ion-icon> 
                Linkedin 
            </a>

            <a href="https://github.com/anuevoperez" target='_blank' rel='noreferrer'> 
              <ion-icon name="logo-github"
                class={styles.icon}>
              </ion-icon> 
                Github 
            </a>

            <a href={cv} download='CV 2023 - Adrián Nuevo.pdf'>
              <ion-icon name="arrow-down-circle-sharp" 
                class={styles.icon}>
              </ion-icon> 
                Download CV 
            </a>

          </div>

        </div>

      </div>
      
    </div>
  )
}

export default Home