import styles from './App.module.css';
import NavBar from './components/NavBar/NavBar';
import About from './sections/About/About';
import Home from './sections/Home/Home';
import Portfolio from './sections/Portfolio/Portfolio';
import Contact from './sections/Contact/Contact';

// Paquetes instalables de npm.

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className={styles.App}>
      <NavBar/>
      <Home/>
      <About/>
      <Portfolio/>
      <Contact/>
    </div>
  );
}

export default App;
