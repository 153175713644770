import React, { useState , useEffect} from "react";
import styles from "./Contact.module.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import sendContactMessage from "../../services/sendContactMessage";
const Contact = () => {
  const [name, setName] = useState({
    value: "",
    validated: false,
    error: "",
  });
  const [message, setMessage] = useState({
    value: "",
    validated: false,
    error: "",
  });
  const [senderEmail, setSenderEmail] = useState({
    value: "",
    validated: false,
    error: "",
  });
  const [formSubmitted, setFormSubmitted] = useState('');
  const [buttonState,setButtonState]= useState(false);
  const [loading, setLoading] = useState(false);

  const handleName = (event) => {
    const { value } = event.target;
    setName((prev) => ({ ...prev, value }));
    setFormSubmitted('')
  };
  const handleEmail = (event) => {
    const { value } = event.target;
    setSenderEmail((prev) => ({ ...prev, value }));
    setFormSubmitted('')
  };
  const handleMessage = (event) => {
    const { value } = event.target;
    setMessage((prev) => ({ ...prev, value }));
    setFormSubmitted('')
  };
  useEffect(
    ()=>{
      setButtonState(name.validated && message.validated && senderEmail.validated);
    },
    [name.validated,message.validated,senderEmail.validated]
  )
  const blurName = (event) => {
    if (!name.value) {
      setName((prev) => ({
        ...prev,
        validated: false,
        error: "* Required field",
      }));
    } else {
      setName((prev) => ({
        ...prev,
        validated: true,
        error: "",
      }));
    }
  };

  const blurEmail = (event) => {
    const regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!name.value) {
      setSenderEmail((prev) => ({
        ...prev,
        validated: false,
        error: "* Required field",
      }));
    } else if (!senderEmail.value.match(regexEmail)) {
      setSenderEmail((prev) => ({
        ...prev,
        validated: false,
        error: "* Invalid email adress",
      }));
    } else {
      setSenderEmail((prev) => ({
        ...prev,
        validated: true,
        error: "",
      }));
    }
  };

  const blurMessage = (event) => {
    
    if (!message.value) {
      setMessage((prev) => ({
        ...prev,
        validated: false,
        error: '* Required field',
      }));
    } else {
      setMessage((prev) => ({
        ...prev,
        validated: true,
        error: "",
      }));
    }
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!(name.validated && message.validated && senderEmail.validated))
    {
      return;
    }

    try{
      setLoading(true);
      setButtonState(false);
      await sendContactMessage(name.value,senderEmail.value,message.value)
      setFormSubmitted('¡Form submitted successfully!')
    }
    catch{
      setFormSubmitted('An error has occurred! Please try again.')
    }
    setLoading(false);
    setButtonState(name.validated && message.validated && senderEmail.validated);
  };

  return (
    <div className={styles.Form} id="contact">
      <div className={styles.Title}>
        <h1> Contact </h1>
      </div>

      <div className={styles.FormBox}>
        <form onSubmit={handleSubmit}>
          <Input
            label={"Name:"}
            name={"nombre"}
            placeholder={"Write your name..."}
            value={name.value}
            onChange={handleName}
            onBlur={blurName}
            error={name.error}
          />
          <Input
            label={"Email:"}
            name={"email"}
            placeholder={"Write your email..."}
            value={senderEmail.value}
            onChange={handleEmail}
            onBlur={blurEmail}
            error={senderEmail.error}
          />
          <Input
            label={"Message:"}
            name={"mensaje"}
            placeholder={"What would you like to tell me?..."}
            value={message.value}
            onChange={handleMessage}
            onBlur={blurMessage}
            error={message.error}
          />
          <div>
            <span className={styles.Alert}> {loading?'Loading ...':formSubmitted}</span>
            <Button type="submit" enabled={buttonState}>
              {" "}
              <ion-icon name="send-outline"></ion-icon> SEND{" "}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
