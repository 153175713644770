import Slider from "react-slick";
import styles from "./Carousel.module.css";
import CarouselItem from "./CarouselItem/CarouselItem";
import { useEffect, useState } from "react";

const Carousel = ({ items }) => {
    
 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isTablet = windowWidth <= 1024 && windowWidth >= 820 ;
  
  

  const settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: isTablet? 5:3,
    slidesToScroll: isTablet? 5:3,
    arrows: false,
    autoplay:true,
    autoplaySpeed: 4000,
    
  }

  return (
    <div className={styles.Carousel}>
      <Slider {...settings}>
        {items.map(({ img, alt }) => (
          <CarouselItem img={img} alt={alt} />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
